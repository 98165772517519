// const NONE = 0;
const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 4;
const THURSDAY = 8;
const FRIDAY = 16;
const SATURDAY = 32;
const SUNDAY = 64;
// const WEEKDAYS = MONDAY | TUESDAY | WEDNESDAY | THURSDAY | FRIDAY;
// const WEEKENDS = SATURDAY | SUNDAY;
// const EVERYDAY = WEEKDAYS | WEEKENDS;

export default {
    listDayOptions() {
        return [
            {
                label: 'Monday',
                value: MONDAY
            },
            {
                label: 'Tuesday',
                value: TUESDAY
            },
            {
                label: 'Wednesday',
                value: WEDNESDAY
            },
            {
                label: 'Thursday',
                value: THURSDAY
            },
            {
                label: 'Friday',
                value: FRIDAY
            },
            {
                label: 'Saturday',
                value: SATURDAY
            },
            {
                label: 'Sunday',
                value: SUNDAY
            }
        ];
    },

    isDaySelected(selectedDays, day) {
        return (
            (selectedDays & day) == day
        );
    },

    getDaysHumanAsArray(selectedDays) {
        let days = [];
        if (this.isDaySelected(selectedDays, MONDAY)) {
            days.push('Monday');
        }
        if (this.isDaySelected(selectedDays, TUESDAY)) {
            days.push('Tuesday');
        }
        if (this.isDaySelected(selectedDays, WEDNESDAY)) {
            days.push('Wednesday');
        }
        if (this.isDaySelected(selectedDays, THURSDAY)) {
            days.push('Thursday');
        }
        if (this.isDaySelected(selectedDays, FRIDAY)) {
            days.push('Friday');
        }
        if (this.isDaySelected(selectedDays, SATURDAY)) {
            days.push('Saturday');
        }
        if (this.isDaySelected(selectedDays, SUNDAY)) {
            days.push('Sunday');
        }
        return days;
    }
}