<template>
  <div class="admin-rota">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button type="default" style="display: inline; margin-right: 15px" @click.prevent="goBack" icon="left">
        </a-button>

        <admin-org-indicator></admin-org-indicator>

        {{ title }}
      </h1>
      <div class="actions" v-if="presence">
        <a-button @click.prevent="loadSchedules" :disabled="isLoading" class="button-margin-left btn-rounded"
          icon="reload" size="large" type="default">Refresh</a-button>

        <a-button @click.prevent="createSchedule" class="button-margin-left btn-rounded" icon="plus" size="large"
          type="primary">Create</a-button>
      </div>
    </div>
    <!-- / Header -->

    <!-- Description -->
    <a-alert style="margin-bottom: 25px"
      message="Users in response teams are automatically responders at any time. Use the rota to limit their availability.">
    </a-alert>
    <!-- / Description -->

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- No results -->
      <a-alert v-if="!schedules.length" type="info"
        message="No rotas to show here. You can create one using the button above.">
      </a-alert>
      <!-- / No results -->

      <!-- {{ schedules }} -->

      <!-- Results -->
      <a-table v-if="schedules.length" :columns="columns" :data-source="schedulesForTable" :pagination="false" bordered>
        <div slot="periods" slot-scope="text, record">
          <div v-for="(period, i) in record.periods" :key="i">
            {{ formatTime(period.startTime) }} -
            {{ formatTime(period.endTime) }}
          </div>
        </div>

        <div slot="actions" slot-scope="text, record">
          <a-popconfirm title="Are you sure?" ok-text="Yes" cancel-text="No" @confirm="deleteSchedule(record)">
            <a-button type="danger" icon="delete">Delete</a-button>
          </a-popconfirm>
        </div>
      </a-table>
      <!-- / Results -->
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import presences from "../../../../api/presences";
import availability from "../../../../api/availability";
const _ = require("lodash");
import rotaHelpers from "../../../../helpers/rota";
import LoadingScreen from "../../../../components/LoadingScreen.vue";

export default {
  data() {
    return {
      isLoadingPresence: false,
      presence: null,

      isLoadingSchedules: false,
      schedules: [],

      isDeleting: false,
    };
  },
  components: { LoadingScreen },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    } else {
      this.loadPresence();
      this.loadSchedules();
    }
  },
  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    isLoading() {
      return this.isLoadingPresence || this.isLoadingSchedules;
    },

    columns() {
      let columns = [
        {
          title: "Days",
          dataIndex: "daysHuman",
        },
        {
          title: "Times",
          dataIndex: "periods",
          scopedSlots: { customRender: "periods" },
        },
        {
          title: "Actions",
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          width: 100,
        },
      ];

      if (this.numberOfWeeks > 1) {
        columns = [
          {
            title: "Weeks",
            dataIndex: "weeksHuman"
          },
          ...columns
        ];
      }

      return columns;
    },

    numberOfWeeks() {
      if (this.selectedOrganisation['content']['$v']['timetableWeeksNullable']) {
        return this.selectedOrganisation['content']['$v']['timetableWeeksNullable'];
      }
      return 1;
    },

    timetableWeeksAlias() {
      if (this.selectedOrganisation['content']['$v']['timetableWeeksAlias']) {
        return this.selectedOrganisation['content']['$v']['timetableWeeksAlias'].split(',');
      }
      return ['Week 1'];
    },

    title() {
      if (this.isLoadingPresence) {
        return "Loading...";
      }
      if (this.presence) {
        return 'Rota For ' + this.presence.displayName;
      }
      return null;
    },

    presenceId() {
      return this.$route.params.presenceId;
    },

    schedulesForTable() {
      let mapped = _.map(this.schedules, (schedule) => {

        let weekLabels = [];
        _.each(schedule.selectedWeeks, week => {
          let weekHuman = this.timetableWeeksAlias[week];
          weekLabels.push(weekHuman);
        });
        let weeksHuman = weekLabels.join(', ');

        let daysHuman = rotaHelpers
          .getDaysHumanAsArray(schedule.selectedDays)
          .join(", ");

        return {
          weeksHuman,
          daysHuman,
          ...schedule,
        };
      });
      return _.orderBy(mapped, "selectedDays");
    },
  },
  methods: {
    goBack() {
      this.$router.push("/admin/responders/availability?reset=false");
    },

    formatTime(time) {
      if (!time) {
        return "00:00";
      }
      if (time.endsWith(":00")) {
        let timeParts = time.split(":");
        timeParts.pop();
        return timeParts.join(":");
      }
      return time;
    },

    loadPresence() {
      let vm = this;
      vm.isLoadingPresence = true;
      vm.presence = null;
      presences
        .getPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.isLoadingPresence = false;
          vm.presence = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingPresence = false;
          vm.$message.error("Error loading user");
          vm.goBack();
        });
    },

    loadSchedules() {
      let vm = this;
      vm.isLoadingSchedules = true;
      vm.schedules = [];
      availability
        .getSchedulesForPresence(this.tenantId, this.presenceId)
        .then((r) => {
          vm.schedules = r.data;
          vm.isLoadingSchedules = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingSchedules = false;
          vm.$message.error("Error loading rota");
          vm.goBack();
        });
    },

    createSchedule() {
      this.$router.push(
        "/admin/responders/availability/" + this.presenceId + "/rota/create"
      );
    },

    deleteSchedule(schedule) {
      let vm = this;
      vm.isDeleting = true;
      availability
        .deleteSchedule(this.tenantId, schedule.id)
        .then(() => {
          vm.isDeleting = false;
          vm.loadSchedules();
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$message.error("Error deleting schedule");
        });
    },
  },
};
</script>

<style lang="scss">
.admin-rota {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .ant-table {
    background: #fff !important;
  }

  .button-margin-left {
    margin-left: 15px;
  }
}
</style>